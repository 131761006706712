.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: rgb(216, 212, 212);
  height: 20%;
  padding: 20px;
  color: white;
  /* size: 50px; */
  text-align: center;
}

.App-title {
  font-size: 1.5em;
  display: inline-block;
  text-align: left;
}

.App-header-menu {
  color: white;
  display: inline-block;
  /* position: fixed; */
  float: left;
  left: 5%;
}

.App-intro {
  font-size: large;
}
/* 
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

@media screen and (max-width: 499px) {
  .App-contents {
    word-wrap: break-word;
    width: 100%;
    margin: auto;
    /* border: 1px solid; */
    /* padding: 15px; */
    /* margin-top: 5px; */
  }
  .content {
    background-color: #f0f0f0;
    /* width: 50%; */
    margin: 10px;
    /* margin-top: 20px; */
    padding: 10px;
    border-radius: 10px;
    /* border: 1px solid; */
  }
  .content-text {
    margin: 5px;
  }
  .content-header {
    /* background-color: #323232; */
    /* margin: -20px; */
    border-bottom: 1px solid #00000021;
    padding: 15px;
    /* color: white; */
  }
  .content-title {
    text-align: center;
  }
  .content-author {
    display: inline-block;
    padding: 0 15px;
    width: auto;
    font-size: 0.8em;
  }
  .content-datetime {
    display: inline-block;
    padding: 0 15px;
    width: auto;
    font-size: 0.8em;
  }
}
@media screen and (min-width: 500px) and (max-width: 999px) {
  .App-contents {
    width: 90%;
    margin: auto;
    /* border: 1px solid; */
    /* padding: 15px; */
    margin-top: 10px;
  }
  .content {
    background-color: #f0f0f0;
    /* width: 50%; */
    margin: 10px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    /* border: 1px solid; */
  }
  .content-text {
    margin: 15px;
  }
  .content-header {
    /* background-color: #323232; */
    /* margin: -20px; */
    border-bottom: 1px solid #00000021;
    padding: 15px;
    /* color: white; */
  }
  .content-title {
    text-align: center;
  }
  .content-author {
    display: inline-block;
    padding: 0 15px;
    width: auto;
    font-size: 0.8em;
  }
  .content-datetime {
    display: inline-block;
    padding: 0 15px;
    width: auto;
    font-size: 0.8em;
  }
}
@media screen and (min-width: 1000px) {
  .App-contents {
    display: block;
    margin: auto;
    /* border: 1px solid; */
    margin-top: 10px;
  }
  .contents {
    width: 60%;
    /* width: 50%; */
    display: inline-block;
    vertical-align: top;
    /* margin: 10px; */
    /* border: 1px solid; */
  }
  .content {
    background-color: #f0f0f0;
    display: block;
    margin: 20px 0;
    border-radius: 10px;
    padding: 0 1% 1%;
  }
  .content-text {
    margin: 15px;
  }
  .content-header {
    /* background-color: #323232; */
    /* margin: -20px; */
    border-bottom: 1px solid #00000021;
    padding: 15px;
    /* color: white; */
  }
  .content-title {
    display: inline-block;
	width: 70%;
    text-align: center;
	vertical-align: middle;
	font-size: 1.2em;
  }
  .content-meta {
    display: inline-block;
	vertical-align: middle;
    width: 20%;
  }
  .content-author {
    display: block;
    padding: 0 15px;
    width: auto;
    font-size: 0.8em;
  }
  .content-datetime {
    display: block;
    padding: 0 15px;
    /* text-align: right; */
    width: auto;
    font-size: 0.8em;
  }
  .content-tags {
    display: inline-block;
	vertical-align: middle;
    /* padding: 0 15px; */
    width: 10%;
    font-size: 0.8em;
  }
  .tag {
    display: block;
    margin: 5px 10px;
  }
  .sidebar {
    width: 20%;
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }
  .timeline {
    position: fixed;
    width: 17%;
    height: 50%;
    display: inline-block;
    padding: 50px 1.5% 0 1.5%;
  }
  /* .twitter-timeline { */
  /* } */
}
