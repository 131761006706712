pre {
    width: 90%;
    margin: 0 auto;
    overflow: scroll;
    padding: 15px;
    background-color: #364549;
    color: #e3e3e3;
}

code {
    display: inline-block;
}

img {
    width: 100%;
}